<template>
  <div class="guest_background">
    <Header></Header>
    <slot />
    <FeedbackContainer />
  </div>
</template>

<style scoped>
.guest_background {
  min-width: 100%;
  min-height: 100%;
  background: var(--white-main);
}
</style>
